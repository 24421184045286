import { TCountryCode } from '@agroop/api/accounts/types';

const resolvedOptions = new Intl.DateTimeFormat().resolvedOptions() as Intl.ResolvedDateTimeFormatOptions;

export const timeZone = resolvedOptions.timeZone;
export const locale = resolvedOptions.locale;
export const countryCode = locale.replace(/.*([A-Z]{2}).*/, '$1') as TCountryCode;

/**
 * It accepts a JSON date string, a Date instance or a timestamp number
 * and returns the date part as yyyy-MM-dd
 */
export const toJSONDate = (d: Date | string | number) =>
  (typeof d === 'string' ? d : (d instanceof Date ? d : new Date(d)).toJSON()).slice(0, 10);
